import { dew as _LifeUpdaterDew } from "./LifeUpdater";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadLifeUpdater = void 0;

  const LifeUpdater_1 = _LifeUpdaterDew();

  async function loadLifeUpdater(engine) {
    await engine.addParticleUpdater("life", container => new LifeUpdater_1.LifeUpdater(container));
  }

  exports.loadLifeUpdater = loadLifeUpdater;
  return exports;
}