import { dew as _PolygonMaskDew } from "./Options/Classes/PolygonMask";
import { dew as _PolygonMaskInstanceDew } from "./PolygonMaskInstance";
import { dew as _UtilsDew } from "../../Utils";
import { dew as _pathsegDew } from "./pathseg";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, {
      enumerable: true,
      get: function () {
        return m[k];
      }
    });
  } : function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
  });

  var __setModuleDefault = exports && exports.__setModuleDefault || (Object.create ? function (o, v) {
    Object.defineProperty(o, "default", {
      enumerable: true,
      value: v
    });
  } : function (o, v) {
    o["default"] = v;
  });

  var __importStar = exports && exports.__importStar || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

    __setModuleDefault(result, mod);

    return result;
  };

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadPolygonMaskPlugin = void 0;

  const PolygonMask_1 = _PolygonMaskDew();

  const PolygonMaskInstance_1 = _PolygonMaskInstanceDew();

  const Utils_1 = _UtilsDew();

  class PolygonMaskPlugin {
    constructor() {
      this.id = "polygonMask";
    }

    getPlugin(container) {
      return new PolygonMaskInstance_1.PolygonMaskInstance(container);
    }

    needsPlugin(options) {
      var _a, _b, _c;

      return (_b = (_a = options === null || options === void 0 ? void 0 : options.polygon) === null || _a === void 0 ? void 0 : _a.enable) !== null && _b !== void 0 ? _b : ((_c = options === null || options === void 0 ? void 0 : options.polygon) === null || _c === void 0 ? void 0 : _c.type) !== undefined && options.polygon.type !== "none";
    }

    loadOptions(options, source) {
      if (!this.needsPlugin(source)) {
        return;
      }

      const optionsCast = options;
      let polygonOptions = optionsCast.polygon;

      if ((polygonOptions === null || polygonOptions === void 0 ? void 0 : polygonOptions.load) === undefined) {
        optionsCast.polygon = polygonOptions = new PolygonMask_1.PolygonMask();
      }

      polygonOptions.load(source === null || source === void 0 ? void 0 : source.polygon);
    }

  }

  async function loadPolygonMaskPlugin(engine) {
    if (!(0, Utils_1.isSsr)() && !("SVGPathSeg" in window)) {
      await Promise.resolve().then(() => __importStar(_pathsegDew()));
    }

    const plugin = new PolygonMaskPlugin();
    await engine.addPlugin(plugin);
  }

  exports.loadPolygonMaskPlugin = loadPolygonMaskPlugin;
  return exports;
}