import { dew as _TrailMakerDew } from "./TrailMaker";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadExternalTrailInteraction = void 0;

  const TrailMaker_1 = _TrailMakerDew();

  async function loadExternalTrailInteraction(engine) {
    await engine.addInteractor("externalTrail", container => new TrailMaker_1.TrailMaker(container));
  }

  exports.loadExternalTrailInteraction = loadExternalTrailInteraction;
  return exports;
}