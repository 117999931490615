import { dew as _ColorUpdaterDew } from "./ColorUpdater";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadColorUpdater = void 0;

  const ColorUpdater_1 = _ColorUpdaterDew();

  async function loadColorUpdater(engine) {
    await engine.addParticleUpdater("color", container => new ColorUpdater_1.ColorUpdater(container));
  }

  exports.loadColorUpdater = loadColorUpdater;
  return exports;
}