import { dew as _AngleUpdaterDew } from "./AngleUpdater";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadAngleUpdater = void 0;

  const AngleUpdater_1 = _AngleUpdaterDew();

  async function loadAngleUpdater(engine) {
    await engine.addParticleUpdater("angle", container => new AngleUpdater_1.AngleUpdater(container));
  }

  exports.loadAngleUpdater = loadAngleUpdater;
  return exports;
}