import { dew as _StrokeColorUpdaterDew } from "./StrokeColorUpdater";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadStrokeColorUpdater = void 0;

  const StrokeColorUpdater_1 = _StrokeColorUpdaterDew();

  async function loadStrokeColorUpdater(engine) {
    await engine.addParticleUpdater("strokeColor", container => new StrokeColorUpdater_1.StrokeColorUpdater(container));
  }

  exports.loadStrokeColorUpdater = loadStrokeColorUpdater;
  return exports;
}