import { dew as _ImageDrawerDew } from "./ImageDrawer";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadImageShape = void 0;

  const ImageDrawer_1 = _ImageDrawerDew();

  async function loadImageShape(engine) {
    const imageDrawer = new ImageDrawer_1.ImageDrawer();
    await engine.addShape("image", imageDrawer);
    await engine.addShape("images", imageDrawer);
  }

  exports.loadImageShape = loadImageShape;
  return exports;
}