import { dew as _CoreDew } from "../../../Core";
import { dew as _UtilsDew } from "../../../Utils";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Connector = void 0;

  const Core_1 = _CoreDew();

  const Utils_1 = _UtilsDew();

  class Connector extends Core_1.ExternalInteractorBase {
    constructor(container) {
      super(container);
    }

    isEnabled() {
      const container = this.container,
            mouse = container.interactivity.mouse,
            events = container.actualOptions.interactivity.events;

      if (!(events.onHover.enable && mouse.position)) {
        return false;
      }

      return (0, Utils_1.isInArray)("connect", events.onHover.mode);
    }

    reset() {}

    interact() {
      const container = this.container,
            options = container.actualOptions;

      if (options.interactivity.events.onHover.enable && container.interactivity.status === "mousemove") {
        const mousePos = container.interactivity.mouse.position;

        if (!mousePos) {
          return;
        }

        const distance = Math.abs(container.retina.connectModeRadius),
              query = container.particles.quadTree.queryCircle(mousePos, distance);
        let i = 0;

        for (const p1 of query) {
          const pos1 = p1.getPosition();

          for (const p2 of query.slice(i + 1)) {
            const pos2 = p2.getPosition(),
                  distMax = Math.abs(container.retina.connectModeDistance),
                  xDiff = Math.abs(pos1.x - pos2.x),
                  yDiff = Math.abs(pos1.y - pos2.y);

            if (xDiff < distMax && yDiff < distMax) {
              container.canvas.drawConnectLine(p1, p2);
            }
          }

          ++i;
        }
      }
    }

  }

  exports.Connector = Connector;
  return exports;
}