import { dew as _AngleDew } from "./Updaters/Angle";
import { dew as _CircleDew } from "./Shapes/Circle";
import { dew as _ColorDew } from "./Updaters/Color";
import { dew as _AttractDew } from "./Interactions/External/Attract";
import { dew as _BounceDew } from "./Interactions/External/Bounce";
import { dew as _BubbleDew } from "./Interactions/External/Bubble";
import { dew as _ConnectDew } from "./Interactions/External/Connect";
import { dew as _GrabDew } from "./Interactions/External/Grab";
import { dew as _RepulseDew } from "./Interactions/External/Repulse";
import { dew as _ImageDew } from "./Shapes/Image";
import { dew as _LifeDew } from "./Updaters/Life";
import { dew as _LineDew } from "./Shapes/Line";
import { dew as _OpacityDew } from "./Updaters/Opacity";
import { dew as _OutModesDew } from "./Updaters/OutModes";
import { dew as _Attract2Dew } from "./Interactions/Particles/Attract";
import { dew as _CollisionsDew } from "./Interactions/Particles/Collisions";
import { dew as _LinksDew } from "./Interactions/Particles/Links";
import { dew as _PolygonDew } from "./Shapes/Polygon";
import { dew as _SizeDew } from "./Updaters/Size";
import { dew as _SquareDew } from "./Shapes/Square";
import { dew as _StarDew } from "./Shapes/Star";
import { dew as _StrokeColorDew } from "./Updaters/StrokeColor";
import { dew as _TextDew } from "./Shapes/Text";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadSlim = void 0;

  const Angle_1 = _AngleDew();

  const Circle_1 = _CircleDew();

  const Color_1 = _ColorDew();

  const Attract_1 = _AttractDew();

  const Bounce_1 = _BounceDew();

  const Bubble_1 = _BubbleDew();

  const Connect_1 = _ConnectDew();

  const Grab_1 = _GrabDew();

  const Repulse_1 = _RepulseDew();

  const Image_1 = _ImageDew();

  const Life_1 = _LifeDew();

  const Line_1 = _LineDew();

  const Opacity_1 = _OpacityDew();

  const OutModes_1 = _OutModesDew();

  const Attract_2 = _Attract2Dew();

  const Collisions_1 = _CollisionsDew();

  const Links_1 = _LinksDew();

  const Polygon_1 = _PolygonDew();

  const Size_1 = _SizeDew();

  const Square_1 = _SquareDew();

  const Star_1 = _StarDew();

  const StrokeColor_1 = _StrokeColorDew();

  const Text_1 = _TextDew();

  async function loadSlim(engine) {
    await (0, Attract_1.loadExternalAttractInteraction)(engine);
    await (0, Bounce_1.loadExternalBounceInteraction)(engine);
    await (0, Bubble_1.loadExternalBubbleInteraction)(engine);
    await (0, Connect_1.loadExternalConnectInteraction)(engine);
    await (0, Grab_1.loadExternalGrabInteraction)(engine);
    await (0, Repulse_1.loadExternalRepulseInteraction)(engine);
    await (0, Attract_2.loadParticlesAttractInteraction)(engine);
    await (0, Collisions_1.loadParticlesCollisionsInteraction)(engine);
    await (0, Links_1.loadParticlesLinksInteraction)(engine);
    await (0, Circle_1.loadCircleShape)(engine);
    await (0, Image_1.loadImageShape)(engine);
    await (0, Line_1.loadLineShape)(engine);
    await (0, Polygon_1.loadPolygonShape)(engine);
    await (0, Square_1.loadSquareShape)(engine);
    await (0, Star_1.loadStarShape)(engine);
    await (0, Text_1.loadTextShape)(engine);
    await (0, Life_1.loadLifeUpdater)(engine);
    await (0, Opacity_1.loadOpacityUpdater)(engine);
    await (0, Size_1.loadSizeUpdater)(engine);
    await (0, Angle_1.loadAngleUpdater)(engine);
    await (0, Color_1.loadColorUpdater)(engine);
    await (0, StrokeColor_1.loadStrokeColorUpdater)(engine);
    await (0, OutModes_1.loadOutModesUpdater)(engine);
  }

  exports.loadSlim = loadSlim;
  return exports;
}