import { dew as _interactionDew } from "./interaction";
import { dew as _pluginDew } from "./plugin";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadParticlesLinksInteraction = void 0;

  const interaction_1 = _interactionDew();

  const plugin_1 = _pluginDew();

  async function loadParticlesLinksInteraction(engine) {
    await (0, interaction_1.loadInteraction)(engine);
    await (0, plugin_1.loadPlugin)(engine);
  }

  exports.loadParticlesLinksInteraction = loadParticlesLinksInteraction;
  return exports;
}