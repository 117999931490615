import { dew as _ColliderDew } from "./Collider";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadParticlesCollisionsInteraction = void 0;

  const Collider_1 = _ColliderDew();

  async function loadParticlesCollisionsInteraction(engine) {
    await engine.addInteractor("particlesCollisions", container => new Collider_1.Collider(container));
  }

  exports.loadParticlesCollisionsInteraction = loadParticlesCollisionsInteraction;
  return exports;
}