import { dew as _LinkerDew } from "./Linker";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadInteraction = void 0;

  const Linker_1 = _LinkerDew();

  async function loadInteraction(engine) {
    await engine.addInteractor("particlesLinks", container => new Linker_1.Linker(container));
  }

  exports.loadInteraction = loadInteraction;
  return exports;
}