import { dew as _WobbleUpdaterDew } from "./WobbleUpdater";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadWobbleUpdater = void 0;

  const WobbleUpdater_1 = _WobbleUpdaterDew();

  async function loadWobbleUpdater(engine) {
    await engine.addParticleUpdater("wobble", container => new WobbleUpdater_1.WobbleUpdater(container));
  }

  exports.loadWobbleUpdater = loadWobbleUpdater;
  return exports;
}