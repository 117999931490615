import { dew as _OutOfCanvasUpdaterDew } from "./OutOfCanvasUpdater";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadOutModesUpdater = void 0;

  const OutOfCanvasUpdater_1 = _OutOfCanvasUpdaterDew();

  async function loadOutModesUpdater(engine) {
    await engine.addParticleUpdater("outModes", container => new OutOfCanvasUpdater_1.OutOfCanvasUpdater(container));
  }

  exports.loadOutModesUpdater = loadOutModesUpdater;
  return exports;
}