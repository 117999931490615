import { dew as _SizeUpdaterDew } from "./SizeUpdater";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadSizeUpdater = void 0;

  const SizeUpdater_1 = _SizeUpdaterDew();

  async function loadSizeUpdater(engine) {
    await engine.addParticleUpdater("size", () => new SizeUpdater_1.SizeUpdater());
  }

  exports.loadSizeUpdater = loadSizeUpdater;
  return exports;
}