import { dew as _SquareDrawerDew } from "./SquareDrawer";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadSquareShape = void 0;

  const SquareDrawer_1 = _SquareDrawerDew();

  async function loadSquareShape(engine) {
    const drawer = new SquareDrawer_1.SquareDrawer();
    await engine.addShape("edge", drawer);
    await engine.addShape("square", drawer);
  }

  exports.loadSquareShape = loadSquareShape;
  return exports;
}