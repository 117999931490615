import { dew as _engineDew } from "./engine";
import { dew as _pjsDew } from "./pjs";
import { dew as _fullDew } from "./full";
import { dew as _CoreDew } from "./Core";
import { dew as _ContainerDew } from "./Core/Container";
import { dew as _EnumsDew } from "./Enums";
import { dew as _Enums2Dew } from "./Plugins/Absorbers/Enums";
import { dew as _Enums3Dew } from "./Plugins/Emitters/Enums";
import { dew as _Enums4Dew } from "./Plugins/PolygonMask/Enums";
import { dew as _UtilsDew } from "./Utils";
import { dew as _TypesDew } from "./Types";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, {
      enumerable: true,
      get: function () {
        return m[k];
      }
    });
  } : function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
  });

  var __exportStar = exports && exports.__exportStar || function (m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
  };

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.tsParticles = exports.pJSDom = exports.particlesJS = exports.Main = exports.Engine = void 0;

  const engine_1 = _engineDew();

  Object.defineProperty(exports, "Engine", {
    enumerable: true,
    get: function () {
      return engine_1.Engine;
    }
  });
  Object.defineProperty(exports, "Main", {
    enumerable: true,
    get: function () {
      return engine_1.Engine;
    }
  });

  const pjs_1 = _pjsDew();

  const full_1 = _fullDew();

  const tsParticles = new engine_1.Engine();
  exports.tsParticles = tsParticles;
  tsParticles.init();
  const {
    particlesJS,
    pJSDom
  } = (0, pjs_1.initPjs)(tsParticles);
  exports.particlesJS = particlesJS;
  exports.pJSDom = pJSDom;
  (0, full_1.loadFull)(tsParticles);

  __exportStar(_CoreDew(), exports);

  __exportStar(_ContainerDew(), exports);

  __exportStar(_EnumsDew(), exports);

  __exportStar(_Enums2Dew(), exports);

  __exportStar(_Enums3Dew(), exports);

  __exportStar(_Enums4Dew(), exports);

  __exportStar(_UtilsDew(), exports);

  __exportStar(_TypesDew(), exports);

  return exports;
}