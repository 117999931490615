import { dew as _BouncerDew } from "./Bouncer";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadExternalBounceInteraction = void 0;

  const Bouncer_1 = _BouncerDew();

  async function loadExternalBounceInteraction(engine) {
    await engine.addInteractor("externalBounce", container => new Bouncer_1.Bouncer(container));
  }

  exports.loadExternalBounceInteraction = loadExternalBounceInteraction;
  return exports;
}